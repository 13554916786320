import fetch from '@giro/shared-utils/fetch.util';

export type BodyType = {
  integration_id: string;
  amount: number;
  payment_link?: boolean;
  type: 'CARTAO';
};

export default async function postApiInstallmentsService(body: BodyType) {
  return fetch('installments', {
    method: 'POST',
    ms: 'CHECKOUT',
    auth: true,
    body: JSON.stringify(body),
  });
}
